export const pricesData = [
  {
    title: 'Psychoterapia indywidualna',
    price: '200 zł / 50 min',
  },
  {
    title: 'Sesja Ruchu Autentycznego',
    price: '200 zł / 50 min',
  },
  {
    title: 'Psychoterapia dla kobiet w ciąży i diady (matka-niemowlę)',
    price: '300 zł / 75 min',
  },
];
